/**
 * http://github.com/Widen/fine-uploader
 *
 * Multiple file upload component with progress-bar, drag-and-drop, support for all modern browsers.
 *
 * Copyright � 2013, Widen Enterprises info@fineupoader.com
 *
 * Licensed under GNU GPL v3, see license.txt.
 */
var qq=function(a){return{hide:function(){a.style.display="none";return this},attach:function(b,c){a.addEventListener?a.addEventListener(b,c,!1):a.attachEvent&&a.attachEvent("on"+b,c);return function(){qq(a).detach(b,c)}},detach:function(b,c){a.removeEventListener?a.removeEventListener(b,c,!1):a.attachEvent&&a.detachEvent("on"+b,c);return this},contains:function(b){return a===b?!0:a.contains?a.contains(b):!!(b.compareDocumentPosition(a)&8)},insertBefore:function(b){b.parentNode.insertBefore(a,b);
return this},remove:function(){a.parentNode.removeChild(a);return this},css:function(b){null!==b.opacity&&("string"!==typeof a.style.opacity&&"undefined"!==typeof a.filters)&&(b.filter="alpha(opacity="+Math.round(100*b.opacity)+")");qq.extend(a.style,b);return this},hasClass:function(b){return RegExp("(^| )"+b+"( |$)").test(a.className)},addClass:function(b){qq(a).hasClass(b)||(a.className+=" "+b);return this},removeClass:function(b){a.className=a.className.replace(RegExp("(^| )"+b+"( |$)")," ").replace(/^\s+|\s+$/g,
"");return this},getByClass:function(b){var c,d=[];if(a.querySelectorAll)return a.querySelectorAll("."+b);c=a.getElementsByTagName("*");qq.each(c,function(a,c){qq(c).hasClass(b)&&d.push(c)});return d},children:function(){for(var b=[],c=a.firstChild;c;)1===c.nodeType&&b.push(c),c=c.nextSibling;return b},setText:function(b){a.innerText=b;a.textContent=b;return this},clearText:function(){return qq(a).setText("")}}};
qq.log=function(a,b){if(window.console)if(!b||"info"===b)window.console.log(a);else if(window.console[b])window.console[b](a);else window.console.log("<"+b+"> "+a)};qq.isObject=function(a){return null!==a&&a&&"object"===typeof a&&a.constructor===Object};qq.isFunction=function(a){return"function"===typeof a};qq.isString=function(a){return"[object String]"===Object.prototype.toString.call(a)};qq.trimStr=function(a){return String.prototype.trim?a.trim():a.replace(/^\s+|\s+$/g,"")};
qq.isFileOrInput=function(a){if(window.File&&a instanceof File)return!0;if(window.HTMLInputElement){if(a instanceof HTMLInputElement&&a.type&&"file"===a.type.toLowerCase())return!0}else if(a.tagName&&"input"===a.tagName.toLowerCase()&&a.type&&"file"===a.type.toLowerCase())return!0;return!1};qq.isBlob=function(a){return window.Blob&&"[object Blob]"===Object.prototype.toString.call(a)};
qq.isXhrUploadSupported=function(){var a=document.createElement("input");a.type="file";return void 0!==a.multiple&&"undefined"!==typeof File&&"undefined"!==typeof FormData&&"undefined"!==typeof(new XMLHttpRequest).upload};qq.isFolderDropSupported=function(a){return a.items&&a.items[0].webkitGetAsEntry};qq.isFileChunkingSupported=function(){return!qq.android()&&qq.isXhrUploadSupported()&&(void 0!==File.prototype.slice||void 0!==File.prototype.webkitSlice||void 0!==File.prototype.mozSlice)};
qq.extend=function(a,b,c){qq.each(b,function(b,e){c&&qq.isObject(e)?(void 0===a[b]&&(a[b]={}),qq.extend(a[b],e,!0)):a[b]=e})};qq.indexOf=function(a,b,c){if(a.indexOf)return a.indexOf(b,c);var c=c||0,d=a.length;for(0>c&&(c+=d);c<d;c+=1)if(a.hasOwnProperty(c)&&a[c]===b)return c;return-1};qq.getUniqueId=function(){return"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,function(a){var b=16*Math.random()|0;return("x"==a?b:b&3|8).toString(16)})};qq.ie=function(){return-1!==navigator.userAgent.indexOf("MSIE")};
qq.ie10=function(){return-1!==navigator.userAgent.indexOf("MSIE 10")};qq.safari=function(){return void 0!==navigator.vendor&&-1!==navigator.vendor.indexOf("Apple")};qq.chrome=function(){return void 0!==navigator.vendor&&-1!==navigator.vendor.indexOf("Google")};qq.firefox=function(){return-1!==navigator.userAgent.indexOf("Mozilla")&&void 0!==navigator.vendor&&""===navigator.vendor};qq.windows=function(){return"Win32"===navigator.platform};qq.android=function(){return-1!==navigator.userAgent.toLowerCase().indexOf("android")};
qq.preventDefault=function(a){a.preventDefault?a.preventDefault():a.returnValue=!1};qq.toElement=function(){var a=document.createElement("div");return function(b){a.innerHTML=b;b=a.firstChild;a.removeChild(b);return b}}();qq.each=function(a,b){var c,d;if(a)for(c in a)if(Object.prototype.hasOwnProperty.call(a,c)&&(d=b(c,a[c]),!1===d))break};
qq.obj2url=function(a,b,c){var d,e=[],f="&",m=function(a,c){var d=b?/\[\]$/.test(b)?b:b+"["+c+"]":c;"undefined"!==d&&"undefined"!==c&&e.push("object"===typeof a?qq.obj2url(a,d,!0):"[object Function]"===Object.prototype.toString.call(a)?encodeURIComponent(d)+"="+encodeURIComponent(a()):encodeURIComponent(d)+"="+encodeURIComponent(a))};if(!c&&b)f=/\?/.test(b)?/\?$/.test(b)?"":"&":"?",e.push(b),e.push(qq.obj2url(a));else if("[object Array]"===Object.prototype.toString.call(a)&&"undefined"!==typeof a){d=
-1;for(c=a.length;d<c;d+=1)m(a[d],d)}else if("undefined"!==typeof a&&null!==a&&"object"===typeof a)for(d in a)a.hasOwnProperty(d)&&m(a[d],d);else e.push(encodeURIComponent(b)+"="+encodeURIComponent(a));return b?e.join(f):e.join(f).replace(/^&/,"").replace(/%20/g,"+")};qq.obj2FormData=function(a,b,c){b||(b=new FormData);qq.each(a,function(a,e){a=c?c+"["+a+"]":a;qq.isObject(e)?qq.obj2FormData(e,b,a):qq.isFunction(e)?b.append(a,e()):b.append(a,e)});return b};
qq.obj2Inputs=function(a,b){var c;b||(b=document.createElement("form"));qq.obj2FormData(a,{append:function(a,e){c=document.createElement("input");c.setAttribute("name",a);c.setAttribute("value",e);b.appendChild(c)}});return b};qq.setCookie=function(a,b,c){var d=new Date,e="";c&&(d.setTime(d.getTime()+864E5*c),e="; expires="+d.toGMTString());document.cookie=a+"="+b+e+"; path=/"};
qq.getCookie=function(a){for(var a=a+"=",b=document.cookie.split(";"),c,d=0;d<b.length;d++){for(c=b[d];" "==c.charAt(0);)c=c.substring(1,c.length);if(0===c.indexOf(a))return c.substring(a.length,c.length)}};qq.getCookieNames=function(a){var b=document.cookie.split(";"),c=[];qq.each(b,function(b,e){var e=qq.trimStr(e),f=e.indexOf("=");e.match(a)&&c.push(e.substr(0,f))});return c};qq.deleteCookie=function(a){qq.setCookie(a,"",-1)};
qq.areCookiesEnabled=function(){var a="qqCookieTest:"+1E5*Math.random();qq.setCookie(a,1);return qq.getCookie(a)?(qq.deleteCookie(a),!0):!1};qq.parseJson=function(a){return window.JSON&&qq.isFunction(JSON.parse)?JSON.parse(a):eval("("+a+")")};qq.DisposeSupport=function(){var a=[];return{dispose:function(){var b;do(b=a.shift())&&b();while(b)},attach:function(){this.addDisposer(qq(arguments[0]).attach.apply(this,Array.prototype.slice.call(arguments,1)))},addDisposer:function(b){a.push(b)}}};
qq.supportedFeatures=function(){var a,b,c,d,e,f;a=!0;try{b=document.createElement("input"),b.type="file",qq(b).hide(),b.disabled&&(a=!1)}catch(m){a=!1}c=(b=a&&qq.isXhrUploadSupported())&&qq.chrome()&&void 0!==navigator.userAgent.match(/Chrome\/[2][1-9]|Chrome\/[3-9][0-9]/);d=b&&qq.isFileChunkingSupported();e=b&&d&&qq.areCookiesEnabled();f=b&&qq.chrome()&&void 0!==navigator.userAgent.match(/Chrome\/[1][4-9]|Chrome\/[2-9][0-9]/);return{uploading:a,ajaxUploading:b,fileDrop:b,folderDrop:c,chunking:d,
resume:e,uploadCustomHeaders:b,uploadNonMultipart:b,itemSizeValidation:b,uploadViaPaste:f,progressBar:b,uploadCors:a&&(void 0!==window.postMessage||b),deleteFileCors:b}}();qq.Promise=function(){var a,b,c,d,e,f=0;return{then:function(e,i){0===f?(c=e,d=i):-1===f&&i?i(b):e&&e(a);return this},done:function(a){0===f?e=a:a();return this},success:function(b){f=1;a=b;c&&c(b);e&&e();return this},failure:function(a){f=-1;b=a;d&&d(a);e&&e();return this}}};
qq.UploadButton=function(a){function b(){var a=document.createElement("input");e.multiple&&a.setAttribute("multiple","multiple");e.acceptFiles&&a.setAttribute("accept",e.acceptFiles);a.setAttribute("type","file");a.setAttribute("name",e.name);qq(a).css({position:"absolute",right:0,top:0,fontFamily:"Arial",fontSize:"118px",margin:0,padding:0,cursor:"pointer",opacity:0});e.element.appendChild(a);d.attach(a,"change",function(){e.onChange(a)});d.attach(a,"mouseover",function(){qq(e.element).addClass(e.hoverClass)});
d.attach(a,"mouseout",function(){qq(e.element).removeClass(e.hoverClass)});d.attach(a,"focus",function(){qq(e.element).addClass(e.focusClass)});d.attach(a,"blur",function(){qq(e.element).removeClass(e.focusClass)});window.attachEvent&&a.setAttribute("tabIndex","-1");return a}var c,d=new qq.DisposeSupport,e={element:null,multiple:!1,acceptFiles:null,name:"file",onChange:function(){},hoverClass:"qq-upload-button-hover",focusClass:"qq-upload-button-focus"};qq.extend(e,a);qq(e.element).css({position:"relative",
overflow:"hidden",direction:"ltr"});c=b();return{getInput:function(){return c},reset:function(){c.parentNode&&qq(c).remove();qq(e.element).removeClass(e.focusClass);c=b()}}};
qq.PasteSupport=function(a){var b;b={targetElement:null,callbacks:{log:function(){},pasteReceived:function(){}}};qq.extend(b,a);(function(){qq(b.targetElement).attach("paste",function(a){(a=a.clipboardData)&&qq.each(a.items,function(a,c){if(c.type&&0===c.type.indexOf("image/")){var f=c.getAsFile();b.callbacks.pasteReceived(f)}})})})();return{reset:function(){}}};
qq.FineUploaderBasic=function(a){this._options={debug:!1,button:null,multiple:!0,maxConnections:3,disableCancelForFormUploads:!1,autoUpload:!0,request:{endpoint:"/server/upload",params:{},paramsInBody:!0,customHeaders:{},forceMultipart:!0,inputName:"qqfile",uuidName:"qquuid",totalFileSizeName:"qqtotalfilesize"},validation:{allowedExtensions:[],sizeLimit:0,minSizeLimit:0,itemLimit:0,stopOnFirstInvalidFile:!0},callbacks:{onSubmit:function(){},onSubmitted:function(){},onComplete:function(){},onCancel:function(){},
onUpload:function(){},onUploadChunk:function(){},onResume:function(){},onProgress:function(){},onError:function(){},onAutoRetry:function(){},onManualRetry:function(){},onValidateBatch:function(){},onValidate:function(){},onSubmitDelete:function(){},onDelete:function(){},onDeleteComplete:function(){},onPasteReceived:function(){}},messages:{typeError:"{file} has an invalid extension. Valid extension(s): {extensions}.",sizeError:"{file} is too large, maximum file size is {sizeLimit}.",minSizeError:"{file} is too small, minimum file size is {minSizeLimit}.",
emptyError:"{file} is empty, please select files again without it.",noFilesError:"No files to upload.",tooManyItemsError:"Too many items ({netItems}) would be uploaded.  Item limit is {itemLimit}.",retryFailTooManyItems:"Retry failed - you have reached your file limit.",onLeave:"The files are being uploaded, if you leave now the upload will be cancelled."},retry:{enableAuto:!1,maxAutoAttempts:3,autoAttemptDelay:5,preventRetryResponseProperty:"preventRetry"},classes:{buttonHover:"qq-upload-button-hover",
buttonFocus:"qq-upload-button-focus"},chunking:{enabled:!1,partSize:2E6,paramNames:{partIndex:"qqpartindex",partByteOffset:"qqpartbyteoffset",chunkSize:"qqchunksize",totalFileSize:"qqtotalfilesize",totalParts:"qqtotalparts",filename:"qqfilename"}},resume:{enabled:!1,id:null,cookiesExpireIn:7,paramNames:{resuming:"qqresume"}},formatFileName:function(a){33<a.length&&(a=a.slice(0,19)+"..."+a.slice(-14));return a},text:{defaultResponseError:"Upload failure reason unknown",sizeSymbols:"kB MB GB TB PB EB".split(" ")},
deleteFile:{enabled:!1,endpoint:"/server/upload",customHeaders:{},params:{}},cors:{expected:!1,sendCredentials:!1},blobs:{defaultName:"misc_data",paramNames:{name:"qqblobname"}},paste:{targetElement:null,defaultName:"pasted_image"}};qq.extend(this._options,a,!0);this._wrapCallbacks();this._disposeSupport=new qq.DisposeSupport;this._filesInProgress=[];this._storedIds=[];this._autoRetries=[];this._retryTimeouts=[];this._preventRetries=[];this._netUploaded=this._netUploadedOrQueued=0;this._paramsStore=
this._createParamsStore("request");this._deleteFileParamsStore=this._createParamsStore("deleteFile");this._endpointStore=this._createEndpointStore("request");this._deleteFileEndpointStore=this._createEndpointStore("deleteFile");this._handler=this._createUploadHandler();this._deleteHandler=this._createDeleteHandler();this._options.button&&(this._button=this._createUploadButton(this._options.button));this._options.paste.targetElement&&(this._pasteHandler=this._createPasteHandler());this._preventLeaveInProgress()};
qq.FineUploaderBasic.prototype={log:function(a,b){this._options.debug&&(!b||"info"===b)?qq.log("[FineUploader] "+a):b&&"info"!==b&&qq.log("[FineUploader] "+a,b)},setParams:function(a,b){null==b?this._options.request.params=a:this._paramsStore.setParams(a,b)},setDeleteFileParams:function(a,b){null==b?this._options.deleteFile.params=a:this._deleteFileParamsStore.setParams(a,b)},setEndpoint:function(a,b){null==b?this._options.request.endpoint=a:this._endpointStore.setEndpoint(a,b)},getInProgress:function(){return this._filesInProgress.length},
getNetUploads:function(){return this._netUploaded},uploadStoredFiles:function(){for(var a;this._storedIds.length;)a=this._storedIds.shift(),this._filesInProgress.push(a),this._handler.upload(a)},clearStoredFiles:function(){this._storedIds=[]},retry:function(a){return this._onBeforeManualRetry(a)?(this._netUploadedOrQueued++,this._handler.retry(a),!0):!1},cancel:function(a){this._handler.cancel(a)},cancelAll:function(){var a=[],b=this;qq.extend(a,this._storedIds);qq.each(a,function(a,d){b.cancel(d)});
this._handler.cancelAll()},reset:function(){this.log("Resetting uploader...");this._handler.reset();this._filesInProgress=[];this._storedIds=[];this._autoRetries=[];this._retryTimeouts=[];this._preventRetries=[];this._button.reset();this._paramsStore.reset();this._endpointStore.reset();this._netUploaded=this._netUploadedOrQueued=0;this._pasteHandler&&this._pasteHandler.reset()},addFiles:function(a,b,c){var d=[],e,f;if(a){if(!window.FileList||!(a instanceof FileList))a=[].concat(a);for(e=0;e<a.length;e+=
1)f=a[e],qq.isFileOrInput(f)?d.push(f):this.log(f+" is not a File or INPUT element!  Ignoring!","warn");this.log("Processing "+d.length+" files or inputs...");this._uploadFileOrBlobDataList(d,b,c)}},addBlobs:function(a,b,c){if(a){var a=[].concat(a),d=[],e=this;qq.each(a,function(a,b){qq.isBlob(b)&&!qq.isFileOrInput(b)?d.push({blob:b,name:e._options.blobs.defaultName}):qq.isObject(b)&&b.blob&&b.name?d.push(b):e.log("addBlobs: entry at index "+a+" is not a Blob or a BlobData object","error")});this._uploadFileOrBlobDataList(d,
b,c)}else this.log("undefined or non-array parameter passed into addBlobs","error")},getUuid:function(a){return this._handler.getUuid(a)},getResumableFilesData:function(){return this._handler.getResumableFilesData()},getSize:function(a){return this._handler.getSize(a)},getName:function(a){return this._handler.getName(a)},getFile:function(a){return this._handler.getFile(a)},deleteFile:function(a){this._onSubmitDelete(a)},setDeleteFileEndpoint:function(a,b){null==b?this._options.deleteFile.endpoint=
a:this._deleteFileEndpointStore.setEndpoint(a,b)},_createUploadButton:function(a){var b=this,c=new qq.UploadButton({element:a,multiple:this._options.multiple&&qq.supportedFeatures.ajaxUploading,acceptFiles:this._options.validation.acceptFiles,onChange:function(a){b._onInputChange(a)},hoverClass:this._options.classes.buttonHover,focusClass:this._options.classes.buttonFocus});this._disposeSupport.addDisposer(function(){c.dispose()});return c},_createUploadHandler:function(){var a=this;return new qq.UploadHandler({debug:this._options.debug,
forceMultipart:this._options.request.forceMultipart,maxConnections:this._options.maxConnections,customHeaders:this._options.request.customHeaders,inputName:this._options.request.inputName,uuidParamName:this._options.request.uuidName,totalFileSizeParamName:this._options.request.totalFileSizeName,cors:this._options.cors,demoMode:this._options.demoMode,paramsInBody:this._options.request.paramsInBody,paramsStore:this._paramsStore,endpointStore:this._endpointStore,chunking:this._options.chunking,resume:this._options.resume,
blobs:this._options.blobs,log:function(b,c){a.log(b,c)},onProgress:function(b,c,d,e){a._onProgress(b,c,d,e);a._options.callbacks.onProgress(b,c,d,e)},onComplete:function(b,c,d,e){a._onComplete(b,c,d,e);a._options.callbacks.onComplete(b,c,d,e)},onCancel:function(b,c){a._onCancel(b,c);a._options.callbacks.onCancel(b,c)},onUpload:function(b,c){a._onUpload(b,c);a._options.callbacks.onUpload(b,c)},onUploadChunk:function(b,c,d){a._options.callbacks.onUploadChunk(b,c,d)},onResume:function(b,c,d){return a._options.callbacks.onResume(b,
c,d)},onAutoRetry:function(b,c,d,e){a._preventRetries[b]=d[a._options.retry.preventRetryResponseProperty];return a._shouldAutoRetry(b,c,d)?(a._maybeParseAndSendUploadError(b,c,d,e),a._options.callbacks.onAutoRetry(b,c,a._autoRetries[b]+1),a._onBeforeAutoRetry(b,c),a._retryTimeouts[b]=setTimeout(function(){a._onAutoRetry(b,c,d)},1E3*a._options.retry.autoAttemptDelay),!0):!1}})},_createDeleteHandler:function(){var a=this;return new qq.DeleteFileAjaxRequestor({maxConnections:this._options.maxConnections,
customHeaders:this._options.deleteFile.customHeaders,paramsStore:this._deleteFileParamsStore,endpointStore:this._deleteFileEndpointStore,demoMode:this._options.demoMode,cors:this._options.cors,log:function(b,c){a.log(b,c)},onDelete:function(b){a._onDelete(b);a._options.callbacks.onDelete(b)},onDeleteComplete:function(b,c,d){a._onDeleteComplete(b,c,d);a._options.callbacks.onDeleteComplete(b,c,d)}})},_createPasteHandler:function(){var a=this;return new qq.PasteSupport({targetElement:this._options.paste.targetElement,
callbacks:{log:function(b,c){a.log(b,c)},pasteReceived:function(b){var c=a._options.callbacks.onPasteReceived;(c=c(b))&&c.then?c.then(function(c){a._handlePasteSuccess(b,c)},function(b){a.log("Ignoring pasted image per paste received callback.  Reason = '"+b+"'")}):a._handlePasteSuccess(b)}}})},_handlePasteSuccess:function(a,b){var c=a.type.split("/")[1],d=b;null==d&&(d=this._options.paste.defaultName);this.addBlobs({name:d+("."+c),blob:a})},_preventLeaveInProgress:function(){var a=this;this._disposeSupport.attach(window,
"beforeunload",function(b){if(a._filesInProgress.length)return b=b||window.event,b.returnValue=a._options.messages.onLeave})},_onSubmit:function(a){this._netUploadedOrQueued++;this._options.autoUpload&&this._filesInProgress.push(a)},_onProgress:function(){},_onComplete:function(a,b,c,d){c.success?this._netUploaded++:this._netUploadedOrQueued--;this._removeFromFilesInProgress(a);this._maybeParseAndSendUploadError(a,b,c,d)},_onCancel:function(a){this._netUploadedOrQueued--;this._removeFromFilesInProgress(a);
clearTimeout(this._retryTimeouts[a]);a=qq.indexOf(this._storedIds,a);!this._options.autoUpload&&0<=a&&this._storedIds.splice(a,1)},_isDeletePossible:function(){return this._options.deleteFile.enabled&&(!this._options.cors.expected||qq.supportedFeatures.deleteFileCors)},_onSubmitDelete:function(a){if(this._isDeletePossible())!1!==this._options.callbacks.onSubmitDelete(a)&&this._deleteHandler.sendDelete(a,this.getUuid(a));else return this.log("Delete request ignored for ID "+a+", delete feature is disabled or request not possible due to CORS on a user agent that does not support pre-flighting.",
"warn"),!1},_onDelete:function(){},_onDeleteComplete:function(a,b,c){var d=this._handler.getName(a);c?(this.log("Delete request for '"+d+"' has failed.","error"),this._options.callbacks.onError(a,d,"Delete request failed with response code "+b.status,b)):(this._netUploadedOrQueued--,this._netUploaded--,this.log("Delete request for '"+d+"' has succeeded."))},_removeFromFilesInProgress:function(a){a=qq.indexOf(this._filesInProgress,a);0<=a&&this._filesInProgress.splice(a,1)},_onUpload:function(){},
_onInputChange:function(a){qq.supportedFeatures.ajaxUploading?this.addFiles(a.files):this.addFiles(a);this._button.reset()},_onBeforeAutoRetry:function(a,b){this.log("Waiting "+this._options.retry.autoAttemptDelay+" seconds before retrying "+b+"...")},_onAutoRetry:function(a,b){this.log("Retrying "+b+"...");this._autoRetries[a]++;this._handler.retry(a)},_shouldAutoRetry:function(a){return!this._preventRetries[a]&&this._options.retry.enableAuto?(void 0===this._autoRetries[a]&&(this._autoRetries[a]=
0),this._autoRetries[a]<this._options.retry.maxAutoAttempts):!1},_onBeforeManualRetry:function(a){var b=this._options.validation.itemLimit;if(this._preventRetries[a])return this.log("Retries are forbidden for id "+a,"warn"),!1;if(this._handler.isValid(a)){var c=this._handler.getName(a);if(!1===this._options.callbacks.onManualRetry(a,c))return!1;if(0<b&&this._netUploadedOrQueued+1>b)return this._itemError("retryFailTooManyItems",""),!1;this.log("Retrying upload for '"+c+"' (id: "+a+")...");this._filesInProgress.push(a);
return!0}this.log("'"+a+"' is not a valid file ID","error");return!1},_maybeParseAndSendUploadError:function(a,b,c,d){if(!c.success)if(d&&200!==d.status&&!c.error)this._options.callbacks.onError(a,b,"XHR returned response code "+d.status,d);else this._options.callbacks.onError(a,b,c.error?c.error:this._options.text.defaultResponseError,d)},_uploadFileOrBlobDataList:function(a,b,c){var d;if(this._isBatchValid(this._getValidationDescriptors(a)))if(0<a.length)for(d=0;d<a.length;d++)if(this._validateFileOrBlobData(a[d]))this._upload(a[d],
b,c);else{if(this._options.validation.stopOnFirstInvalidFile)break}else this._itemError("noFilesError","")},_upload:function(a,b,c){var a=this._handler.add(a),d=this._handler.getName(a);b&&this.setParams(b,a);c&&this.setEndpoint(c,a);!1!==this._options.callbacks.onSubmit(a,d)&&(this._onSubmit(a,d),this._options.callbacks.onSubmitted(a,d),this._options.autoUpload?this._handler.upload(a):this._storeForLater(a))},_storeForLater:function(a){this._storedIds.push(a)},_isBatchValid:function(a){var b;b=this._options.validation.itemLimit;
var c=this._netUploadedOrQueued+a.length;if(a=!1!==this._options.callbacks.onValidateBatch(a))0===b||c<=b?a=!0:(a=!1,b=this._options.messages.tooManyItemsError.replace(/\{netItems\}/g,c).replace(/\{itemLimit\}/g,b),this._batchError(b));return a},_validateFileOrBlobData:function(a){var b,c,d;b=this._getValidationDescriptor(a);c=b.name;d=b.size;return!1===this._options.callbacks.onValidate(b)?!1:qq.isFileOrInput(a)&&!this._isAllowedExtension(c)?(this._itemError("typeError",c),!1):0===d?(this._itemError("emptyError",
c),!1):d&&this._options.validation.sizeLimit&&d>this._options.validation.sizeLimit?(this._itemError("sizeError",c),!1):d&&d<this._options.validation.minSizeLimit?(this._itemError("minSizeError",c),!1):!0},_itemError:function(a,b){function c(a,b){d=d.replace(a,b)}var d=this._options.messages[a],e=[],f=[].concat(b),m=f[0],i;qq.each(this._options.validation.allowedExtensions,function(a,b){qq.isString(b)&&e.push(b)});i=e.join(", ").toLowerCase();c("{file}",this._options.formatFileName(m));c("{extensions}",
i);c("{sizeLimit}",this._formatSize(this._options.validation.sizeLimit));c("{minSizeLimit}",this._formatSize(this._options.validation.minSizeLimit));i=d.match(/(\{\w+\})/g);null!==i&&qq.each(i,function(a,b){c(b,f[a])});this._options.callbacks.onError(null,m,d);return d},_batchError:function(a){this._options.callbacks.onError(null,null,a)},_isAllowedExtension:function(a){var b=this._options.validation.allowedExtensions,c=!1;if(!b.length)return!0;qq.each(b,function(b,e){if(qq.isString(e)&&null!=a.match(RegExp("\\."+
e+"$","i")))return c=!0,!1});return c},_formatSize:function(a){var b=-1;do a/=1024,b++;while(99<a);return Math.max(a,0.1).toFixed(1)+this._options.text.sizeSymbols[b]},_wrapCallbacks:function(){var a,b;a=this;b=function(b,c,f){try{return c.apply(a,f)}catch(m){a.log("Caught exception in '"+b+"' callback - "+m.message,"error")}};for(var c in this._options.callbacks)(function(){var d,e;d=c;e=a._options.callbacks[d];a._options.callbacks[d]=function(){return b(d,e,arguments)}})()},_parseFileOrBlobDataName:function(a){return qq.isFileOrInput(a)?
a.value?a.value.replace(/.*(\/|\\)/,""):null!==a.fileName&&void 0!==a.fileName?a.fileName:a.name:a.name},_parseFileOrBlobDataSize:function(a){var b;qq.isFileOrInput(a)?a.value||(b=null!==a.fileSize&&void 0!==a.fileSize?a.fileSize:a.size):b=a.blob.size;return b},_getValidationDescriptor:function(a){var b,c;c={};b=this._parseFileOrBlobDataName(a);a=this._parseFileOrBlobDataSize(a);c.name=b;void 0!==a&&(c.size=a);return c},_getValidationDescriptors:function(a){var b=this,c=[];qq.each(a,function(a,e){c.push(b._getValidationDescriptor(e))});
return c},_createParamsStore:function(a){var b={},c=this;return{setParams:function(a,c){var f={};qq.extend(f,a);b[c]=f},getParams:function(d){var e={};null!=d&&b[d]?qq.extend(e,b[d]):qq.extend(e,c._options[a].params);return e},remove:function(a){return delete b[a]},reset:function(){b={}}}},_createEndpointStore:function(a){var b={},c=this;return{setEndpoint:function(a,c){b[c]=a},getEndpoint:function(d){return null!=d&&b[d]?b[d]:c._options[a].endpoint},remove:function(a){return delete b[a]},reset:function(){b=
{}}}}};
qq.DragAndDrop=function(a){function b(a){var c,d,e=new qq.Promise;a.isFile?a.file(function(a){i.push(a);e.success()},function(b){f.callbacks.dropLog("Problem parsing '"+a.fullPath+"'.  FileError code "+b.code+".","error");e.failure()}):a.isDirectory&&(c=a.createReader(),c.readEntries(function(a){var c=a.length;for(d=0;d<a.length;d+=1)b(a[d]).done(function(){c=c-1;c===0&&e.success()});a.length||e.success()},function(b){f.callbacks.dropLog("Problem parsing '"+a.fullPath+"'.  FileError code "+b.code+
".","error");e.failure()}));return e}function c(a){var c,d,e=[],o=new qq.Promise;f.callbacks.processingDroppedFiles();m.dropDisabled(!0);if(1<a.files.length&&!f.allowMultipleItems)f.callbacks.processingDroppedFilesComplete([]),f.callbacks.dropError("tooManyFilesError",""),m.dropDisabled(!1),o.failure();else{i=[];if(qq.isFolderDropSupported(a)){c=a.items;for(a=0;a<c.length;a+=1)(d=c[a].webkitGetAsEntry())&&(d.isFile?i.push(c[a].getAsFile()):e.push(b(d).done(function(){e.pop();e.length===0&&o.success()})))}else i=
a.files;0===e.length&&o.success()}return o}function d(a){m=new qq.UploadDropZone({element:a,onEnter:function(b){qq(a).addClass(f.classes.dropActive);b.stopPropagation()},onLeaveNotDescendants:function(){qq(a).removeClass(f.classes.dropActive)},onDrop:function(b){f.hideDropZonesBeforeEnter&&qq(a).hide();qq(a).removeClass(f.classes.dropActive);c(b.dataTransfer).done(function(){var a=i;f.callbacks.dropLog("Grabbed "+a.length+" dropped files.");m.dropDisabled(!1);f.callbacks.processingDroppedFilesComplete(a)})}});
g.addDisposer(function(){m.dispose()});f.hideDropZonesBeforeEnter&&qq(a).hide()}function e(a){var b;qq.each(a.dataTransfer.types,function(a,c){if("Files"===c)return b=!0,!1});return b}var f,m,i=[],g=new qq.DisposeSupport;f={dropZoneElements:[],hideDropZonesBeforeEnter:!1,allowMultipleItems:!0,classes:{dropActive:null},callbacks:new qq.DragAndDrop.callbacks};qq.extend(f,a,!0);(function(){var a=f.dropZoneElements;qq.each(a,function(a,b){d(b)});a.length&&(!qq.ie()||qq.ie10())&&g.attach(document,"dragenter",
function(b){!m.dropDisabled()&&e(b)&&qq.each(a,function(a,b){qq(b).css({display:"block"})})});g.attach(document,"dragleave",function(b){f.hideDropZonesBeforeEnter&&qq.FineUploader.prototype._leaving_document_out(b)&&qq.each(a,function(a,b){qq(b).hide()})});g.attach(document,"drop",function(b){f.hideDropZonesBeforeEnter&&qq.each(a,function(a,b){qq(b).hide()});b.preventDefault()})})();return{setupExtraDropzone:function(a){f.dropZoneElements.push(a);d(a)},removeDropzone:function(a){var b,c=f.dropZoneElements;
for(b in c)if(c[b]===a)return c.splice(b,1)},dispose:function(){g.dispose();m.dispose()}}};qq.DragAndDrop.callbacks=function(){return{processingDroppedFiles:function(){},processingDroppedFilesComplete:function(){},dropError:function(a,b){qq.log("Drag & drop error code '"+a+" with these specifics: '"+b+"'","error")},dropLog:function(a,b){qq.log(a,b)}}};
qq.UploadDropZone=function(a){function b(){return qq.safari()||qq.firefox()&&qq.windows()}function c(a){if(qq.ie()&&!qq.ie10())return!1;var b=a.dataTransfer,c=qq.safari(),a=qq.ie10()?!0:"none"!==b.effectAllowed;return b&&a&&(b.files||!c&&b.types.contains&&b.types.contains("Files"))}function d(a){void 0!==a&&(m=a);return m}var e,f,m,i,g=new qq.DisposeSupport;e={element:null,onEnter:function(){},onLeave:function(){},onLeaveNotDescendants:function(){},onDrop:function(){}};qq.extend(e,a);f=e.element;
(function(){i||(b?g.attach(document,"dragover",function(a){a.preventDefault()}):g.attach(document,"dragover",function(a){a.dataTransfer&&(a.dataTransfer.dropEffect="none",a.preventDefault())}),i=!0)})();(function(){g.attach(f,"dragover",function(a){if(c(a)){var b=qq.ie()?null:a.dataTransfer.effectAllowed;a.dataTransfer.dropEffect="move"===b||"linkMove"===b?"move":"copy";a.stopPropagation();a.preventDefault()}});g.attach(f,"dragenter",function(a){if(!d()&&c(a))e.onEnter(a)});g.attach(f,"dragleave",
function(a){if(c(a)){e.onLeave(a);var b=document.elementFromPoint(a.clientX,a.clientY);if(!qq(this).contains(b))e.onLeaveNotDescendants(a)}});g.attach(f,"drop",function(a){!d()&&c(a)&&(a.preventDefault(),e.onDrop(a))})})();return{dropDisabled:function(a){return d(a)},dispose:function(){g.dispose()}}};
qq.FineUploader=function(a){qq.FineUploaderBasic.apply(this,arguments);qq.extend(this._options,{element:null,listElement:null,dragAndDrop:{extraDropzones:[],hideDropzones:!0,disableDefaultDropzone:!1},text:{uploadButton:"Upload a file",cancelButton:"Cancel",retryButton:"Retry",deleteButton:"Delete",failUpload:"Upload failed",dragZone:"Drop files here to upload",dropProcessing:"Processing dropped files...",formatProgress:"{percent}% of {total_size}",waitingForResponse:"Processing..."},template:'<div class="qq-uploader">'+
(!this._options.dragAndDrop||!this._options.dragAndDrop.disableDefaultDropzone?'<div class="qq-upload-drop-area"><span>{dragZoneText}</span></div>':"")+(!this._options.button?'<div class="qq-upload-button"><div>{uploadButtonText}</div></div>':"")+'<span class="qq-drop-processing"><span>{dropProcessingText}</span><span class="qq-drop-processing-spinner"></span></span>'+(!this._options.listElement?'<ul class="qq-upload-list"></ul>':"")+"</div>",fileTemplate:'<li><div class="qq-progress-bar"></div><span class="qq-upload-spinner"></span><span class="qq-upload-finished"></span><span class="qq-upload-file"></span><span class="qq-upload-size"></span><a class="qq-upload-cancel" href="#">{cancelButtonText}</a><a class="qq-upload-retry" href="#">{retryButtonText}</a><a class="qq-upload-delete" href="#">{deleteButtonText}</a><span class="qq-upload-status-text">{statusText}</span></li>',
classes:{button:"qq-upload-button",drop:"qq-upload-drop-area",dropActive:"qq-upload-drop-area-active",list:"qq-upload-list",progressBar:"qq-progress-bar",file:"qq-upload-file",spinner:"qq-upload-spinner",finished:"qq-upload-finished",retrying:"qq-upload-retrying",retryable:"qq-upload-retryable",size:"qq-upload-size",cancel:"qq-upload-cancel",deleteButton:"qq-upload-delete",retry:"qq-upload-retry",statusText:"qq-upload-status-text",success:"qq-upload-success",fail:"qq-upload-fail",successIcon:null,
failIcon:null,dropProcessing:"qq-drop-processing",dropProcessingSpinner:"qq-drop-processing-spinner"},failedUploadTextDisplay:{mode:"default",maxChars:50,responseProperty:"error",enableTooltip:!0},messages:{tooManyFilesError:"You may only drop one file",unsupportedBrowser:"Unrecoverable error - this browser does not permit file uploading of any kind."},retry:{showAutoRetryNote:!0,autoRetryNote:"Retrying {retryNum}/{maxAuto}...",showButton:!1},deleteFile:{forceConfirm:!1,confirmMessage:"Are you sure you want to delete {filename}?",
deletingStatusText:"Deleting...",deletingFailedText:"Delete failed"},display:{fileSizeOnSubmit:!1},paste:{promptForName:!1,namePromptMessage:"Please name this image"},showMessage:function(a){setTimeout(function(){window.alert(a)},0)},showConfirm:function(a,c,d){setTimeout(function(){window.confirm(a)?c():d&&d()},0)},showPrompt:function(a,c){var d=new qq.Promise,e=window.prompt(a,c);null!=e&&0<qq.trimStr(e).length?d.success(e):d.failure("Undefined or invalid user-supplied value.");return d}},!0);qq.extend(this._options,
a,!0);!qq.supportedFeatures.uploading||this._options.cors.expected&&!qq.supportedFeatures.uploadCors?this._options.element.innerHTML="<div>"+this._options.messages.unsupportedBrowser+"</div>":(this._wrapCallbacks(),this._options.template=this._options.template.replace(/\{dragZoneText\}/g,this._options.text.dragZone),this._options.template=this._options.template.replace(/\{uploadButtonText\}/g,this._options.text.uploadButton),this._options.template=this._options.template.replace(/\{dropProcessingText\}/g,
this._options.text.dropProcessing),this._options.fileTemplate=this._options.fileTemplate.replace(/\{cancelButtonText\}/g,this._options.text.cancelButton),this._options.fileTemplate=this._options.fileTemplate.replace(/\{retryButtonText\}/g,this._options.text.retryButton),this._options.fileTemplate=this._options.fileTemplate.replace(/\{deleteButtonText\}/g,this._options.text.deleteButton),this._options.fileTemplate=this._options.fileTemplate.replace(/\{statusText\}/g,""),this._element=this._options.element,
this._element.innerHTML=this._options.template,this._listElement=this._options.listElement||this._find(this._element,"list"),this._classes=this._options.classes,this._button||(this._button=this._createUploadButton(this._find(this._element,"button"))),this._bindCancelAndRetryEvents(),this._dnd=this._setupDragAndDrop(),this._options.paste.targetElement&&this._options.paste.promptForName&&this._setupPastePrompt())};qq.extend(qq.FineUploader.prototype,qq.FineUploaderBasic.prototype);
qq.extend(qq.FineUploader.prototype,{clearStoredFiles:function(){qq.FineUploaderBasic.prototype.clearStoredFiles.apply(this,arguments);this._listElement.innerHTML=""},addExtraDropzone:function(a){this._dnd.setupExtraDropzone(a)},removeExtraDropzone:function(a){return this._dnd.removeDropzone(a)},getItemByFileId:function(a){for(var b=this._listElement.firstChild;b;){if(b.qqFileId==a)return b;b=b.nextSibling}},reset:function(){qq.FineUploaderBasic.prototype.reset.apply(this,arguments);this._element.innerHTML=
this._options.template;this._listElement=this._options.listElement||this._find(this._element,"list");this._options.button||(this._button=this._createUploadButton(this._find(this._element,"button")));this._bindCancelAndRetryEvents();this._dnd.dispose();this._dnd=this._setupDragAndDrop()},_removeFileItem:function(a){a=this.getItemByFileId(a);qq(a).remove()},_setupDragAndDrop:function(){var a=this,b=this._find(this._element,"dropProcessing"),c=this._options.dragAndDrop.extraDropzones,d;d=function(a){a.preventDefault()};
this._options.dragAndDrop.disableDefaultDropzone||c.push(this._find(this._options.element,"drop"));return new qq.DragAndDrop({dropZoneElements:c,hideDropZonesBeforeEnter:this._options.dragAndDrop.hideDropzones,allowMultipleItems:this._options.multiple,classes:{dropActive:this._options.classes.dropActive},callbacks:{processingDroppedFiles:function(){var c=a._button.getInput();qq(b).css({display:"block"});qq(c).attach("click",d)},processingDroppedFilesComplete:function(c){var f=a._button.getInput();
qq(b).hide();qq(f).detach("click",d);c&&a.addFiles(c)},dropError:function(b,c){a._itemError(b,c)},dropLog:function(b,c){a.log(b,c)}}})},_leaving_document_out:function(a){return(qq.chrome()||qq.safari()&&qq.windows())&&0==a.clientX&&0==a.clientY||qq.firefox()&&!a.relatedTarget},_storeForLater:function(a){qq.FineUploaderBasic.prototype._storeForLater.apply(this,arguments);var b=this.getItemByFileId(a);qq(this._find(b,"spinner")).hide()},_find:function(a,b){var c=qq(a).getByClass(this._options.classes[b])[0];
if(!c)throw Error("element not found "+b);return c},_onSubmit:function(a,b){qq.FineUploaderBasic.prototype._onSubmit.apply(this,arguments);this._addToList(a,b)},_onProgress:function(a,b,c,d){qq.FineUploaderBasic.prototype._onProgress.apply(this,arguments);var e,f,m,i;e=this.getItemByFileId(a);f=this._find(e,"progressBar");m=Math.round(100*(c/d));c===d?(i=this._find(e,"cancel"),qq(i).hide(),qq(f).hide(),qq(this._find(e,"statusText")).setText(this._options.text.waitingForResponse),this._displayFileSize(a)):
(this._displayFileSize(a,c,d),qq(f).css({display:"block"}));qq(f).css({width:m+"%"})},_onComplete:function(a,b,c,d){qq.FineUploaderBasic.prototype._onComplete.apply(this,arguments);var e=this.getItemByFileId(a);qq(this._find(e,"statusText")).clearText();qq(e).removeClass(this._classes.retrying);qq(this._find(e,"progressBar")).hide();(!this._options.disableCancelForFormUploads||qq.supportedFeatures.ajaxUploading)&&qq(this._find(e,"cancel")).hide();qq(this._find(e,"spinner")).hide();c.success?(this._isDeletePossible()&&
this._showDeleteLink(a),qq(e).addClass(this._classes.success),this._classes.successIcon&&(this._find(e,"finished").style.display="inline-block",qq(e).addClass(this._classes.successIcon))):(qq(e).addClass(this._classes.fail),this._classes.failIcon&&(this._find(e,"finished").style.display="inline-block",qq(e).addClass(this._classes.failIcon)),this._options.retry.showButton&&!this._preventRetries[a]&&qq(e).addClass(this._classes.retryable),this._controlFailureTextDisplay(e,c))},_onUpload:function(a,
b){qq.FineUploaderBasic.prototype._onUpload.apply(this,arguments);this._showSpinner(a)},_onCancel:function(a,b){qq.FineUploaderBasic.prototype._onCancel.apply(this,arguments);this._removeFileItem(a)},_onBeforeAutoRetry:function(a){var b,c,d,e,f;qq.FineUploaderBasic.prototype._onBeforeAutoRetry.apply(this,arguments);b=this.getItemByFileId(a);c=this._find(b,"progressBar");this._showCancelLink(b);c.style.width=0;qq(c).hide();this._options.retry.showAutoRetryNote&&(c=this._find(b,"statusText"),d=this._autoRetries[a]+
1,e=this._options.retry.maxAutoAttempts,f=this._options.retry.autoRetryNote.replace(/\{retryNum\}/g,d),f=f.replace(/\{maxAuto\}/g,e),qq(c).setText(f),1===d&&qq(b).addClass(this._classes.retrying))},_onBeforeManualRetry:function(a){var b=this.getItemByFileId(a);if(qq.FineUploaderBasic.prototype._onBeforeManualRetry.apply(this,arguments))return this._find(b,"progressBar").style.width=0,qq(b).removeClass(this._classes.fail),qq(this._find(b,"statusText")).clearText(),this._showSpinner(a),this._showCancelLink(b),
!0;qq(b).addClass(this._classes.retryable);return!1},_onSubmitDelete:function(a){if(this._isDeletePossible())!1!==this._options.callbacks.onSubmitDelete(a)&&(this._options.deleteFile.forceConfirm?this._showDeleteConfirm(a):this._sendDeleteRequest(a));else return this.log("Delete request ignored for file ID "+a+", delete feature is disabled.","warn"),!1},_onDeleteComplete:function(a,b,c){qq.FineUploaderBasic.prototype._onDeleteComplete.apply(this,arguments);var d=this.getItemByFileId(a),e=this._find(d,
"spinner"),d=this._find(d,"statusText");qq(e).hide();c?(qq(d).setText(this._options.deleteFile.deletingFailedText),this._showDeleteLink(a)):this._removeFileItem(a)},_sendDeleteRequest:function(a){var b=this.getItemByFileId(a),c=this._find(b,"deleteButton"),b=this._find(b,"statusText");qq(c).hide();this._showSpinner(a);qq(b).setText(this._options.deleteFile.deletingStatusText);this._deleteHandler.sendDelete(a,this.getUuid(a))},_showDeleteConfirm:function(a){var b=this._options.deleteFile.confirmMessage.replace(/\{filename\}/g,
this._handler.getName(a));this.getUuid(a);var c=this;this._options.showConfirm(b,function(){c._sendDeleteRequest(a)})},_addToList:function(a,b){var c=qq.toElement(this._options.fileTemplate);if(this._options.disableCancelForFormUploads&&!qq.supportedFeatures.ajaxUploading){var d=this._find(c,"cancel");qq(d).remove()}c.qqFileId=a;d=this._find(c,"file");qq(d).setText(this._options.formatFileName(b));qq(this._find(c,"size")).hide();this._options.multiple||(this._handler.cancelAll(),this._clearList());
this._listElement.appendChild(c);this._options.display.fileSizeOnSubmit&&qq.supportedFeatures.ajaxUploading&&this._displayFileSize(a)},_clearList:function(){this._listElement.innerHTML="";this.clearStoredFiles()},_displayFileSize:function(a,b,c){var d=this.getItemByFileId(a),a=this._formatSize(this.getSize(a)),d=this._find(d,"size");void 0!==b&&void 0!==c&&(a=this._formatProgress(b,c));qq(d).css({display:"inline"});qq(d).setText(a)},_bindCancelAndRetryEvents:function(){var a=this;this._disposeSupport.attach(this._listElement,
"click",function(b){var b=b||window.event,c=b.target||b.srcElement;if(qq(c).hasClass(a._classes.cancel)||qq(c).hasClass(a._classes.retry)||qq(c).hasClass(a._classes.deleteButton)){qq.preventDefault(b);for(b=c.parentNode;void 0===b.qqFileId;)b=b.parentNode;qq(c).hasClass(a._classes.deleteButton)?a.deleteFile(b.qqFileId):qq(c).hasClass(a._classes.cancel)?a.cancel(b.qqFileId):(qq(b).removeClass(a._classes.retryable),a.retry(b.qqFileId))}})},_formatProgress:function(a,b){var c=this._options.text.formatProgress,
d=Math.round(100*(a/b)),c=c.replace("{percent}",d),d=this._formatSize(b);return c=c.replace("{total_size}",d)},_controlFailureTextDisplay:function(a,b){var c,d,e,f;c=this._options.failedUploadTextDisplay.mode;d=this._options.failedUploadTextDisplay.maxChars;e=this._options.failedUploadTextDisplay.responseProperty;"custom"===c?((c=b[e])?c.length>d&&(f=c.substring(0,d)+"..."):(c=this._options.text.failUpload,this.log("'"+e+"' is not a valid property on the server response.","warn")),qq(this._find(a,
"statusText")).setText(f||c),this._options.failedUploadTextDisplay.enableTooltip&&this._showTooltip(a,c)):"default"===c?qq(this._find(a,"statusText")).setText(this._options.text.failUpload):"none"!==c&&this.log("failedUploadTextDisplay.mode value of '"+c+"' is not valid","warn")},_showTooltip:function(a,b){a.title=b},_showSpinner:function(a){this._find(this.getItemByFileId(a),"spinner").style.display="inline-block"},_showCancelLink:function(a){if(!this._options.disableCancelForFormUploads||qq.supportedFeatures.ajaxUploading)a=
this._find(a,"cancel"),qq(a).css({display:"inline"})},_showDeleteLink:function(a){a=this._find(this.getItemByFileId(a),"deleteButton");qq(a).css({display:"inline"})},_itemError:function(a,b){this._options.showMessage(qq.FineUploaderBasic.prototype._itemError.apply(this,arguments))},_batchError:function(a){qq.FineUploaderBasic.prototype._batchError.apply(this,arguments);this._options.showMessage(a)},_setupPastePrompt:function(){var a=this;this._options.callbacks.onPasteReceived=function(){return a._options.showPrompt(a._options.paste.namePromptMessage,
a._options.paste.defaultName)}}});
qq.AjaxRequestor=function(a){function b(a){var b=qq.indexOf(g,a),d=j.maxConnections;delete n[a];g.splice(b,1);g.length>=d&&b<d&&(a=g[d-1],c(a))}function c(a){var b=new XMLHttpRequest,c=f(),g={},r;j.onSend(a);j.paramsStore.getParams&&(g=j.paramsStore.getParams(a));r=g;var s=j.endpointStore.getEndpoint(a),z=n[a].addToPath;void 0!==z&&(s+="/"+z);r=i&&r?qq.obj2url(r,s):s;n[a].xhr=b;b.onreadystatechange=d(a);b.open(c,r,!0);j.cors.expected&&j.cors.sendCredentials&&(b.withCredentials=!0);e(a);m("Sending "+
c+" request for "+a);!i&&g?b.send(qq.obj2url(g,"")):b.send()}function d(a){var c=n[a].xhr;return function(){if(4===c.readyState){var d=n[a].xhr,e=f(),g=!1;b(a);0<=qq.indexOf(j.successfulResponseCodes,d.status)||(g=!0,m(e+" request for "+a+" has failed - response code "+d.status,"error"));j.onComplete(a,d,g)}}}function e(a){var b=n[a].xhr,a=j.customHeaders;b.setRequestHeader("X-Requested-With","XMLHttpRequest");b.setRequestHeader("Cache-Control","no-cache");qq.each(a,function(a,c){b.setRequestHeader(a,
c)})}function f(){return j.demoMode?"GET":j.method}var m,i,g=[],n=[],j={method:"POST",maxConnections:3,customHeaders:{},endpointStore:{},paramsStore:{},successfulResponseCodes:[200],demoMode:!1,cors:{expected:!1,sendCredentials:!1},log:function(){},onSend:function(){},onComplete:function(){},onCancel:function(){}};qq.extend(j,a);m=j.log;i="GET"===f()||"DELETE"===f();return{send:function(a,b){n[a]={addToPath:b};g.push(a)<=j.maxConnections&&c(a)},cancel:function(a){var c=n[a].xhr,d=f();c?(c.onreadystatechange=
null,c.abort(),b(a),m("Cancelled "+d+" for "+a),j.onCancel(a),a=!0):a=!1;return a}}};
qq.DeleteFileAjaxRequestor=function(a){var b,c={endpointStore:{},maxConnections:3,customHeaders:{},paramsStore:{},demoMode:!1,cors:{expected:!1,sendCredentials:!1},log:function(){},onDelete:function(){},onDeleteComplete:function(){}};qq.extend(c,a);b=new qq.AjaxRequestor({method:"DELETE",endpointStore:c.endpointStore,paramsStore:c.paramsStore,maxConnections:c.maxConnections,customHeaders:c.customHeaders,successfulResponseCodes:[200,202,204],demoMode:c.demoMode,log:c.log,onSend:c.onDelete,onComplete:c.onDeleteComplete});
return{sendDelete:function(a,e){b.send(a,e);c.log("Submitted delete file request for "+a)}}};qq.WindowReceiveMessage=function(a){var b={};qq.extend({log:function(){}},a);return{receiveMessage:function(a,d){var e=function(a){d(a.data)};window.postMessage?b[a]=qq(window).attach("message",e):log("iframe message passing not supported in this browser!","error")},stopReceivingMessages:function(a){window.postMessage&&(a=b[a])&&a()}}};
qq.UploadHandler=function(a){var b=[],c,d,e,f;c={debug:!1,forceMultipart:!0,paramsInBody:!1,paramsStore:{},endpointStore:{},cors:{expected:!1,sendCredentials:!1},maxConnections:3,uuidParamName:"qquuid",totalFileSizeParamName:"qqtotalfilesize",chunking:{enabled:!1,partSize:2E6,paramNames:{partIndex:"qqpartindex",partByteOffset:"qqpartbyteoffset",chunkSize:"qqchunksize",totalParts:"qqtotalparts",filename:"qqfilename"}},resume:{enabled:!1,id:null,cookiesExpireIn:7,paramNames:{resuming:"qqresume"}},blobs:{paramNames:{name:"qqblobname"}},
log:function(){},onProgress:function(){},onComplete:function(){},onCancel:function(){},onUpload:function(){},onUploadChunk:function(){},onAutoRetry:function(){},onResume:function(){}};qq.extend(c,a);d=c.log;e=function(a){var a=qq.indexOf(b,a),d=c.maxConnections;0<=a&&(b.splice(a,1),b.length>=d&&a<d&&(a=b[d-1],f.upload(a)))};f=qq.supportedFeatures.ajaxUploading?new qq.UploadHandlerXhr(c,e,d):new qq.UploadHandlerForm(c,e,d);return{add:function(a){return f.add(a)},upload:function(a){if(b.push(a)<=c.maxConnections)return f.upload(a)},
retry:function(a){return 0<=qq.indexOf(b,a)?f.upload(a,!0):this.upload(a)},cancel:function(a){d("Cancelling "+a);c.paramsStore.remove(a);f.cancel(a);e(a)},cancelAll:function(){var a=this,c=[];qq.extend(c,b);qq.each(c,function(b,c){a.cancel(c)});b=[]},getName:function(a){return f.getName(a)},getSize:function(a){if(f.getSize)return f.getSize(a)},getFile:function(a){if(f.getFile)return f.getFile(a)},getQueue:function(){return b},reset:function(){d("Resetting upload handler");b=[];f.reset()},getUuid:function(a){return f.getUuid(a)},
isValid:function(a){return f.isValid(a)},getResumableFilesData:function(){return f.getResumableFilesData?f.getResumableFilesData():[]}}};
qq.UploadHandlerForm=function(a,b,c){function d(a){void 0!==l[a]&&(l[a](),delete l[a])}function e(a,b){var c=a.id;r[j[c]]=b;l[c]=qq(a).attach("load",function(){n[c]&&(o("Received iframe load event for CORS upload request (file id "+c+")"),u[c]=setTimeout(function(){var a="No valid message received from loaded iframe for file id "+c;o(a,"error");b({error:a})},1E3))});x.receiveMessage(c,function(a){o("Received the following window message: '"+a+"'");var b=qq.parseJson(a),e=b.uuid;e&&r[e]?(clearTimeout(u[c]),
delete u[c],d(c),a=r[e],delete r[e],x.stopReceivingMessages(c),a(b)):e||o("'"+a+"' does not contain a UUID - ignoring.")})}function f(a,b){g.cors.expected?e(a,b):l[a.id]=qq(a).attach("load",function(){o("Received response for "+a.id);if(a.parentNode){try{if(a.contentDocument&&a.contentDocument.body&&"false"==a.contentDocument.body.innerHTML)return}catch(c){o("Error when attempting to access iframe during handling of upload response ("+c+")","error")}b()}})}function m(a){var b=qq.toElement('<iframe src="javascript:false;" name="'+
a+'" />');b.setAttribute("id",a);b.style.display="none";document.body.appendChild(b);return b}function i(a,b){var c=g.paramsStore.getParams(a),d=qq.toElement('<form method="'+(g.demoMode?"GET":"POST")+'" enctype="multipart/form-data"></form>'),e=g.endpointStore.getEndpoint(a),f=e;c[g.uuidParamName]=j[a];g.paramsInBody?qq.obj2Inputs(c,d):f=qq.obj2url(c,e);d.setAttribute("action",f);d.setAttribute("target",b.name);d.style.display="none";document.body.appendChild(d);return d}var g=a,n=[],j=[],l={},u=
{},o=c,x=new qq.WindowReceiveMessage({log:o}),r={},s;return s={add:function(a){a.setAttribute("name",g.inputName);var b=n.push(a)-1;j[b]=qq.getUniqueId();a.parentNode&&qq(a).remove();return b},getName:function(a){if(s.isValid(a))return n[a].value.replace(/.*(\/|\\)/,"");o(a+" is not a valid item ID.","error")},isValid:function(a){return void 0!==n[a]},reset:function(){n=[];j=[];l={}},getUuid:function(a){return j[a]},cancel:function(a){g.onCancel(a,this.getName(a));delete n[a];delete j[a];delete l[a];
g.cors.expected&&(clearTimeout(u[a]),delete u[a],x.stopReceivingMessages(a));if(a=document.getElementById(a))a.setAttribute("src","java"+String.fromCharCode(115)+"cript:false;"),qq(a).remove()},upload:function(a){var c=n[a],e=s.getName(a),l=m(a),h;if(!c)throw Error("file with passed id was not added, or already uploaded or cancelled");g.onUpload(a,this.getName(a));h=i(a,l);h.appendChild(c);f(l,function(c){o("iframe loaded");if(!c){var f;try{var h=l.contentDocument||l.contentWindow.document,j=h.body.innerHTML;
o("converting iframe's innerHTML to JSON");o("innerHTML = "+j);j&&j.match(/^<pre/i)&&(j=h.body.firstChild.firstChild.nodeValue);f=qq.parseJson(j)}catch(k){o("Error when attempting to parse form upload response ("+k+")","error"),f={success:!1}}c=f}d(a);g.cors.expected||qq(l).remove();if(c.success||!g.onAutoRetry(a,e,c))g.onComplete(a,e,c),b(a)});o("Sending upload request for "+a);h.submit();qq(h).remove();return a}}};
qq.UploadHandlerXhr=function(a,b,c){function d(a,b,c){var d=p.getSize(a),a=p.getName(a);b[k.chunking.paramNames.partIndex]=c.part;b[k.chunking.paramNames.partByteOffset]=c.start;b[k.chunking.paramNames.chunkSize]=c.size;b[k.chunking.paramNames.totalParts]=c.count;b[k.totalFileSizeParamName]=d;w&&(b[k.chunking.paramNames.filename]=a)}function e(a,b){var c=k.chunking.partSize,d=p.getSize(a),e=h[a].file||h[a].blobData.blob,g=c*b,c=g+c>=d?d:g+c,d=f(a),l;e.slice?l=e.slice(g,c):e.mozSlice?l=e.mozSlice(g,
c):e.webkitSlice&&(l=e.webkitSlice(g,c));return{part:b,start:g,end:c,count:d,blob:l,size:c-g}}function f(a){a=p.getSize(a);return Math.ceil(a/k.chunking.partSize)}function m(a){var b=new XMLHttpRequest;return h[a].xhr=b}function i(a,b,c,d){var e=new FormData,f=k.demoMode?"GET":"POST",g=k.endpointStore.getEndpoint(d),l=g,j=p.getName(d),o=p.getSize(d),i=h[d].blobData;a[k.uuidParamName]=h[d].uuid;w&&(a[k.totalFileSizeParamName]=o,i&&(a[k.blobs.paramNames.name]=i.name));k.paramsInBody||(w||(a[k.inputName]=
j),l=qq.obj2url(a,g));b.open(f,l,!0);k.cors.expected&&k.cors.sendCredentials&&(b.withCredentials=!0);return w?(k.paramsInBody&&qq.obj2FormData(a,e),e.append(k.inputName,c),e):c}function g(a,b){var c=k.customHeaders,d=h[a].file||h[a].blobData.blob;b.setRequestHeader("X-Requested-With","XMLHttpRequest");b.setRequestHeader("Cache-Control","no-cache");w||(b.setRequestHeader("Content-Type","application/octet-stream"),b.setRequestHeader("X-Mime-Type",d.type));qq.each(c,function(a,c){b.setRequestHeader(a,
c)})}function n(a,b,c){var d=p.getName(a),e=p.getSize(a);h[a].attemptingResume=!1;k.onProgress(a,d,e,e);k.onComplete(a,d,b,c);delete h[a].xhr;B(a)}function j(a){var b=h[a].remainingChunkIdxs[0],c=e(a,b),f=m(a),l=p.getSize(a),j=p.getName(a),n;void 0===h[a].loaded&&(h[a].loaded=0);v&&h[a].file&&x(a,c);f.onreadystatechange=o(a,f);f.upload.onprogress=function(c){if(c.lengthComputable){var d=c.loaded+h[a].loaded,c=c.total,f=e(a,b),c=c-f.size,g=p.getSize(a),f=f.count,l=c-h[a].initialRequestOverhead;h[a].lastRequestOverhead=
c;if(b===0){h[a].lastChunkIdxProgress=0;h[a].initialRequestOverhead=c;h[a].estTotalRequestsSize=g+f*c}else if(h[a].lastChunkIdxProgress!==b){h[a].lastChunkIdxProgress=b;h[a].estTotalRequestsSize=h[a].estTotalRequestsSize+l}k.onProgress(a,j,d,h[a].estTotalRequestsSize)}};k.onUploadChunk(a,j,u(c));n=k.paramsStore.getParams(a);d(a,n,c);h[a].attemptingResume&&(n[k.resume.paramNames.resuming]=!0);n=i(n,f,c.blob,a);g(a,f);t("Sending chunked upload request for item "+a+": bytes "+(c.start+1)+"-"+c.end+" of "+
l);f.send(n)}function l(a){t("Server has ordered chunking effort to be restarted on next attempt for item ID "+a,"error");v&&(r(a),h[a].attemptingResume=!1);h[a].remainingChunkIdxs=[];delete h[a].loaded;delete h[a].estTotalRequestsSize;delete h[a].initialRequestOverhead}function u(a){return{partIndex:a.part,startByte:a.start+1,endByte:a.end,totalParts:a.count}}function o(a,b){return function(){if(4===b.readyState){var c;if(h[a]){t("xhr - server response received for "+a);t("responseText = "+b.responseText);
try{c=qq.parseJson(b.responseText)}catch(d){t("Error when attempting to parse xhr response text ("+d+")","error"),c={}}if(200!==b.status||!c.success||c.reset)if(c.reset&&l(a),h[a].attemptingResume&&c.reset)h[a].attemptingResume=!1,t("Server has declared that it cannot handle resume for item ID "+a+" - starting from the first chunk","error"),l(a),p.upload(a,!0);else{var f=p.getName(a);k.onAutoRetry(a,f,c,b)||n(a,c,b)}else if(A){var f=h[a].remainingChunkIdxs.shift(),g=e(a,f);h[a].attemptingResume=!1;
var f=h[a],o=f.loaded,g=g.size,i;i=w?h[a].lastRequestOverhead:0;f.loaded=o+(g+i);0<h[a].remainingChunkIdxs.length?j(a):(v&&r(a),n(a,c,b))}else n(a,c,b)}}}}function x(a,b){var c=p.getUuid(a),d=h[a].loaded,e=h[a].initialRequestOverhead,f=h[a].estTotalRequestsSize,g=s(a);qq.setCookie(g,c+q+b.part+q+d+q+e+q+f,k.resume.cookiesExpireIn)}function r(a){h[a].file&&(a=s(a),qq.deleteCookie(a))}function s(a){var b=p.getName(a),a=p.getSize(a),c=k.chunking.partSize,b="qqfilechunk"+q+encodeURIComponent(b)+q+a+q+
c;void 0!==y&&(b+=q+y);return b}function z(a){var b=h[a].file||h[a].blobData.blob,c=p.getName(a),d,e;h[a].loaded=0;d=m(a);d.upload.onprogress=function(b){b.lengthComputable&&(h[a].loaded=b.loaded,k.onProgress(a,c,b.loaded,b.total))};d.onreadystatechange=o(a,d);e=k.paramsStore.getParams(a);b=i(e,d,b,a);g(a,d);t("Sending upload request for "+a);d.send(b)}var k=a,B=b,t=c,h=[],q="|",A=k.chunking.enabled&&qq.supportedFeatures.chunking,v=k.resume.enabled&&A&&qq.supportedFeatures.resume,y;y=null!==k.resume.id&&
void 0!==k.resume.id&&!qq.isFunction(k.resume.id)&&!qq.isObject(k.resume.id)?k.resume.id:void 0;var w=k.forceMultipart||k.paramsInBody,p;return p={add:function(a){if(a instanceof File)a=h.push({file:a})-1;else if(qq.isBlob(a.blob))a=h.push({blobData:a})-1;else throw Error("Passed obj in not a File or BlobData (in qq.UploadHandlerXhr)");h[a].uuid=qq.getUniqueId();return a},getName:function(a){if(p.isValid(a)){var b=h[a].file,a=h[a].blobData;return b?null!==b.fileName&&void 0!==b.fileName?b.fileName:
b.name:a.name}t(a+" is not a valid item ID.","error")},getSize:function(a){a=h[a].file||h[a].blobData.blob;return qq.isFileOrInput(a)?null!=a.fileSize?a.fileSize:a.size:a.size},getFile:function(a){if(h[a])return h[a].file||h[a].blobData.blob},getLoaded:function(a){return h[a].loaded||0},isValid:function(a){return void 0!==h[a]},reset:function(){h=[]},getUuid:function(a){return h[a].uuid},upload:function(a,b){var c=this.getName(a);k.onUpload(a,c);if(A){var d=p.getName(a),c=0,g,l;if(!h[a].remainingChunkIdxs||
0===h[a].remainingChunkIdxs.length){h[a].remainingChunkIdxs=[];if(v&&!b&&h[a].file){a:{g=qq.getCookie(s(a));l=p.getName(a);var o,i,n;var setVoid=true;if(g){g=g.split(q);if(5===g.length){l=g[0];o=parseInt(g[1],10);i=parseInt(g[2],10);n=parseInt(g[3],10);g=parseInt(g[4],10);g={uuid:l,part:o,lastByteSent:i,initialRequestOverhead:n,estTotalRequestsSize:g};setVoid=false;}else{t("Ignoring previously stored resume/chunk cookie for "+l+" - old cookie format","warn")}}if(setVoid){g=void 0}}g&&(l=e(a,g.part),!1!==k.onResume(a,d,u(l))&&(c=g.part,h[a].uuid=
g.uuid,h[a].loaded=g.lastByteSent,h[a].estTotalRequestsSize=g.estTotalRequestsSize,h[a].initialRequestOverhead=g.initialRequestOverhead,h[a].attemptingResume=!0,t("Resuming "+d+" at partition index "+c)))}for(d=f(a)-1;d>=c;d-=1)h[a].remainingChunkIdxs.unshift(d)}j(a)}else z(a)},cancel:function(a){var b=h[a].xhr;k.onCancel(a,this.getName(a));b&&(b.onreadystatechange=null,b.abort());v&&r(a);delete h[a]},getResumableFilesData:function(){var a=[],b=[];return A&&v?(a=void 0===y?qq.getCookieNames(RegExp("^qqfilechunk\\"+
q+".+\\"+q+"\\d+\\"+q+k.chunking.partSize+"=")):qq.getCookieNames(RegExp("^qqfilechunk\\"+q+".+\\"+q+"\\d+\\"+q+k.chunking.partSize+"\\"+q+y+"=")),qq.each(a,function(a,c){var d=c.split(q),e=qq.getCookie(c).split(q);b.push({name:decodeURIComponent(d[1]),size:d[2],uuid:e[0],partIdx:e[1]})}),b):[]}}};
(function(a){var b,c,d,e,f,m,i,g,n,j;m=["uploaderType"];d=function(a){a&&(a=g(a),i(a),"basic"===f("uploaderType")?b(new qq.FineUploaderBasic(a)):b(new qq.FineUploader(a)));return c};e=function(a,b){var d=c.data("fineuploader");if(b)void 0===d&&(d={}),d[a]=b,c.data("fineuploader",d);else return void 0===d?null:d[a]};b=function(a){return e("uploader",a)};f=function(a,b){return e(a,b)};i=function(b){var d=b.callbacks={},b=new qq.FineUploaderBasic;a.each(b._options.callbacks,function(a){var b,e;b=/^on(\w+)/.exec(a)[1];
b=b.substring(0,1).toLowerCase()+b.substring(1);e=c;d[a]=function(){var a=Array.prototype.slice.call(arguments);return e.triggerHandler(b,a)}})};g=function(b,d){var e,j;e=void 0===d?"basic"!==b.uploaderType?{element:c[0]}:{}:d;a.each(b,function(b,c){0<=a.inArray(b,m)?f(b,c):c instanceof a?e[b]=c[0]:a.isPlainObject(c)?(e[b]={},g(c,e[b])):a.isArray(c)?(j=[],a.each(c,function(b,c){c instanceof a?a.merge(j,c):j.push(c)}),e[b]=j):e[b]=c});if(void 0===d)return e};n=function(c){return"string"===a.type(c)&&
!c.match(/^_/)&&void 0!==b()[c]};j=function(a){var c=[],d=Array.prototype.slice.call(arguments,1);g(d,c);return b()[a].apply(b(),c)};a.fn.fineUploader=function(e){var f=this,g=arguments,i=[];this.each(function(m,s){c=a(s);if(b()&&n(e)){if(i.push(j.apply(f,g)),1===f.length)return!1}else"object"===typeof e||!e?d.apply(f,g):a.error("Method "+e+" does not exist on jQuery.fineUploader")});return 1===i.length?i[0]:1<i.length?i:this}})(jQuery);
(function(a){function b(a){a||(a={});a.dropZoneElements=[i];a=e(a);d(a);c(new qq.DragAndDrop(a));return i}function c(a){var b;var c=i.data(m);a?(void 0===c&&(c={}),c.dndInstance=a,i.data(m,c)):b=void 0===c?null:c.dndInstance;return b}function d(b){var c=b.callbacks={};new qq.FineUploaderBasic;a.each(new qq.DragAndDrop.callbacks,function(a){var b;b=i;c[a]=function(){var c=Array.prototype.slice.call(arguments);return b.triggerHandler(a,c)}})}function e(b,c){var d,f;d=void 0===c?{}:c;a.each(b,function(b,
c){c instanceof a?d[b]=c[0]:a.isPlainObject(c)?(d[b]={},e(c,d[b])):a.isArray(c)?(f=[],a.each(c,function(b,c){c instanceof a?a.merge(f,c):f.push(c)}),d[b]=f):d[b]=c});if(void 0===c)return d}function f(a){var b=[],d=Array.prototype.slice.call(arguments,1);e(d,b);return c()[a].apply(c(),b)}var m="fineUploaderDnd",i;a.fn.fineUploaderDnd=function(d){var e=this,j=arguments,l=[];this.each(function(m,o){i=a(o);if(c()&&"string"===a.type(d)&&"dispose"===d&&void 0!==c()[d]){if(l.push(f.apply(e,j)),1===e.length)return!1}else"object"===
typeof d||!d?b.apply(e,j):a.error("Method "+d+" does not exist in Fine Uploader's DnD module.")});return 1===l.length?l[0]:1<l.length?l:this}})(jQuery);